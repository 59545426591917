.Crop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    .Mask {
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: .5);
    }
    .Buttons {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50% , 0);
        width: 100%;
        max-width: 400px;
        display: flex;
        justify-content: space-between;
        .Button {
            border-radius: 5px;
            background-color: var(--clr-primary);
            font-size: 24px;
            color: #fff;
            width: 50%;
            margin:  0 10px;
            text-align: center;
            padding: 10px;
            cursor: pointer;
            transition: .3s;
            &:hover {
                background-color: rgba($color: #38A0FF, $alpha: .8);
            }
            &Cancel {
                @extend .Button;
                background-color: transparent;
                color: #fff;
                border: 1px solid var(--clr-primary);
            }
        }
    }
    .reactEasyCrop_Image {
        width: unset;
    }
}

@media only screen and (max-width: 700px) {
    .Crop {
            .Buttons{
                justify-content: center;
                .Button {
                    width: 150px;
                    font-size: 18px;
                }
            }
        }
}