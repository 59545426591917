@mixin truncateMixin($lineClamp:1){
    
}

@mixin scrollMixin(){
    overflow: overlay;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(169, 169, 169);
  }
}