.InvitePaper{
	max-width: 588px;
	width: 100%;
	padding: 32px;
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
	min-height: 80vh;

	.tabpanelContent{
		padding: 0;
	}

	.tabHeader{
		margin-bottom: 30px;
	}

	.closeBtn{
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		padding: 3px;
		
	}

	.addBtn{
		background-color: transparent;
		color: var(--clr-primary);
		font-family: 'Roboto-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-transform: none;

		&:hover{
			background-color: transparent;
		}
	}

	.uploadBtn{
		width: 168px;
		height: 36px;
		margin-left: 32px;
		background-color: transparent;
		color: var(--clr-primary);
		border: 1px solid var(--clr-primary);
		border-radius: 4px;
		font-family: 'Roboto-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		text-transform: unset;
		/* identical to box height, or 125% */
		
		letter-spacing: 0.015em;
		
		/* Primary/Blue 100 */
		
		color: var(--clr-primary);

		&:hover{
			box-shadow: none;
			background: rgba(56, 160, 255, 0.2);
		}
	}

	.submitBtn{
		width: 168px;
		height: 36px;
		background-color: var(--clr-primary);
		color: #fff;
		border: none;
		border-radius: 4px;
		font-family: 'Roboto-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		text-transform: unset;
		box-shadow: none;

		&:hover{
			box-shadow: none;
			background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #38A0FF;
		}
	}

	.dataContainer{
		max-height: 235px;
		overflow: auto;

		.emailLine{
			display: flex;
			width: 100%;
			align-items: center;
			position: relative;
			height: 40px;
			margin-bottom: 20px;

			&_Btn{
				display: none;
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);

				&:hover{
					background-color: transparent;
				}
			}
			
			.emailLine_Text{
				width: 100%;
				display: flex;
				align-items: center;
				height: 40px;
				border-bottom: 1px solid #CCCCCC;
			}

			&:hover{
				.emailLine_Btn{
					display: block;
					padding: 0;
					background-color: transparent;
				}
				.emailLine_Text{
					background-color: #F6F6F7;
				}
			}
		}

		.avatar{
			width: 30px;
			height: 30px;
			margin-right: 16px;
			background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #38A0FF;
			color: #fff;
		}
	}

	.total{
		font-family: 'Roboto-Regular';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: var(--clr-primary);

	}

	.groupBtn{
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
		padding: 32px;
	}

	.title{
		position: relative;
		font-family: 'Roboto-Regular';
		font-weight: 400;
		text-align: center;
		font-size: 28px;
		line-height: 34px;
		letter-spacing: -0.02em;
		color: #333333;
		margin-bottom: 24px;
	}

	.tabHeader{
		width: 100%;
	}

	.tab{
		// width: 50%;
		
		width: 20%;
		font-family: 'Roboto-Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 28px;
		/* identical to box height, or 156% */

		display: flex;
		align-items: center;
		text-align: center;
		text-transform: unset;
	}

	.addField{
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: stretch;
		margin-bottom: 32px;

		&Input{
			font-family: 'Roboto-Regular';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;

			color: #333333;

			::placeholder{
				font-family: 'Roboto-Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				
				color: #666666;
				
			}
		}
	}

	.tabSingle{
		justify-content: center;
	}
}