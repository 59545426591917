
@font-face {
  font-family: "Roboto-Black";
  // font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-BlackItalic";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/Roboto-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  // font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-medium";
  font-style: normal;
  src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}

  #root{
    display: flex;
    width:100%;
    height: 100%;
  }
  :root {
    --toggle-size: 1.5rem;
    --switch-w: 4em;
    --switch-h: 2em;
    --switch-handle-scale: .65;
    --switch-off-handle-x: -.125em;
    --switch-on-handle-x: calc(100% - .125em);
    --switch-transition-duration: .2s;
    --clr-primary: #41b60b;
    --clr-bg-primary: #F6F6F7;
    --clr-primary-hover: linear-gradient(0deg, #0D80C3, #0D80C3), #0E6AA3;
    --clr-primary-press: linear-gradient(0deg, #2D80CC, #2D80CC), #38A0FF;
}
    
  html {
    scroll-behavior: smooth;
    // font-size: 62.5%;
  }

  body{
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    overflow-x: hidden;
  }
  .container {
    max-width: 1170px;
    margin: 0 auto;
    // width: 100vw;
}