.rc-profile-popover-z-index{
    z-index: 0 !important;
  
  }

  .password-date{
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #666666;

  }

  .textField .MuiFormLabel-root {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;    
    font-size: 16px;
    line-height: 24px;
    color: #BBBBBB;
  }

  .textField .MuiInputBase-input{
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;    
    font-size: 16px;
    line-height: 24px;
  }