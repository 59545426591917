.embed-event-wrapper {
  width: 100%;
  min-height: 100vh;
  background: #f6f7fa;

  .embed-header {
    background: #ffffff;
    padding: 0 23px;

    .exit {
      color: #666666;

      img {
        margin-right: 7px;
      }

      p {
        font-weight: 500;
        font-family: "Roboto-Medium";
        font-size: 16px;
        line-height: 24px;
        color: #666666;
      }

      .exit-btn:hover {
        background: none;
        background-color: none;
      }
    }
  }

  .box-embed {
    min-height: 100vh;
    //max-width: 942px;
    max-width: 90vw;
    margin: 76px auto 0;
    //padding-bottom: 100px;
    color: #333333;

    .title {
      font-weight: 500;
      font-family: "Roboto-Medium";
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      margin-bottom: 24px;
    }

    .sub-title {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 48px;
    }

    .container-embed-mobile {
      display: none;
    }

    .container-embed {
      .btn-wrap {
        position: relative;
        transition: all linear .3s;   
        border-radius: 4px;
        background-color: #fff;

        img {
          width: 100%;
        }

        .content {
          position: absolute;
          width: 100%;
          top: 70%;

          .header-content {
            font-weight: 500;
            font-family: "Roboto-Medium";
            font-size: 23px;
            line-height: 28px;
            text-align: center;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #666666;
          }
        }
      }

      .btn-wrap:hover {
        box-shadow: 2px 2px 9px #cccccc;
        transform: scale(0.99);
      }
    }

    .embed-wrap-top {
      display: flex;
    }

    .embed-wrap-mobile {
      display: none;
    }

    .embed-wrap {
      display: flex;
      background: #ffffff;
      margin: 0 20%;

      .embed-content {

        //width: 50%;
        .header {
          font-weight: 500;
          font-family: "Roboto-Medium";
          font-size: 23px;
          line-height: 28px;
          color: #333333;
          text-align: left;
        }

        .sub-header {
          text-align: left;
          margin-top: 8px;
          padding-bottom: 24px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }

        .input-title {
          border: 1px dashed var(--primary-color, rgb(0, 105, 255));
        }
      }

      .embed-left {
        border: 1px solid #f6f7fa;
        padding: 32px;
        width: 60%;

        .text-embed-code {
          padding: 12px;
          border: 1px dashed var(--primary-color, rgb(0, 105, 255));

          textarea {
            white-space: pre-line;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #666666;
            font-family: "Courier", sans-serif;
          }
        }

        .container-title {
          .time-embed {
            font-weight: 500;
            font-family: "Roboto-Medium";
            font-size: 12px;
            line-height: 20px;
            color: #666666;
            margin-bottom: 8px;
          }

          .title-event {
            font-weight: 400;
            font-size: 23px;
            line-height: 28px;
            color: #333333;
          }
        }

        .btn-action {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;

          .btn-cancel {
            text-transform: none;
            padding: 8px 12px;
            gap: 8px;
            width: 128px;
            height: 36px;
            background: #ffffff;
            border: 1px solid var(--clr-primary);
            border-radius: 4px;

            font-weight: 500;
            font-family: "Roboto-Medium";
            font-size: 16px;
            line-height: 20px;
            color: var(--clr-primary);

            &:hover {
              background: rgba(56, 160, 255, 0.1);
              border: 1px solid var(--clr-primary);
            }
          }

          .btn-copy {
            text-transform: none;
            background: var(--clr-primary);
            border-radius: 4px;
            box-shadow: none;
            padding: 8px 12px;
            gap: 8px;

            width: 128px;
            height: 36px;

            font-weight: 500;
            font-family: "Roboto-Medium";
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;

            &:hover {
              background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #38A0FF;
              box-shadow: none;
            }
          }
        }

        .btn-style {
          text-transform: none;
        }
      }

      .inline-embed-css {
        width: 100% !important;
      }

      .embed-right-hidden {
        display: none;
      }

      .embed-right {
        border: 1px solid #f6f7fa;
        padding: 32px;
        width: 40%;

        .container-title {
          display: flex;
          flex-direction: column;
          text-align: right;

          .img-publish {
            float: right;
            margin-bottom: 10px;

            img {
              margin-right: 11px;
            }
          }
        }

        .group-name-header {
          padding: 24px;
          border-bottom: 1px solid #ececee;
          display: flex;
          justify-content: space-between;
        }

        .group-right-content {

          //padding: 24px;
          .right-content {
            font-weight: 500;
            font-family: "Roboto-Medium";
            font-size: 23px;
            line-height: 28px;
            color: #333333;
          }

          .describe {
            margin-top: 8px;
            padding-bottom: 24px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }

          .text-input {
            // margin-top: 15px;
            // margin-bottom: 15px;
            // .title-input {
            //   font-weight: bold;
            //   margin-bottom: 5px;
            // }
            display: flex;

            .count-number {
              border-bottom: 1px solid #333333;

              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #bbbbbb;
            }

            input {
              font-family: 'Roboto-Regular';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #333333;
            }

            .MuiOutlinedInput-inputMarginDense {
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #333333;
            }

            .MuiInputBase-inputMarginDense {
              //padding: 12px 0;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #333333;
            }

            .MuiInput-underline::before {
              border-bottom: 1px solid #333333;
            }

            .MuiInput-underline::after {
              border-bottom: none;
            }
          }

          .group-choose-color {
            display: flex;
            //justify-content: space-between;
            //align-items: ;
            margin-top: 25px;

            //width: 100%;
            button {
              width: 100%;
              padding: 0;
              justify-content: space-between;
            }

            .show-color {
              display: inline-block;
              box-sizing: border-box;
              width: 24px;
              height: 24px;
              border: 1px solid rgba(0, 0, 0, 0.2);
              border-radius: 5px;
            }

            .text {
              text-transform: none;
              font-family: 'Roboto-Regular';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #333333;
              text-align: left;
              width: 70%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .embed-wrap-mobile {
    display: none !important;
  }

  .embed-wrap {
    //font-size: 13px;
    margin: 0 12% !important;
    // .embed-left {
    //   .container-title {
    //     .title-event {
    //       font-size: 20px !important;
    //     }
    //   }

    //   .btn-action {
    //     button {
    //       font-size: 15px !important;
    //     }
    //   }
    // }

    // .embed-content {
    //   .header {
    //     font-size: 20px !important;
    //   }
    // }

    // .embed-right {
    //   .right-content {
    //     font-size: 20px !important;
    //   }
    // }
  }
}

@media only screen and (max-width: 1000px) {
  .embed-wrap-mobile {
    display: none !important;
  }

  .embed-wrap {
    margin: 0 10% !important;
  }
}

@media only screen and (max-width: 900px) {
  .embed-wrap-mobile {
    display: none !important;
  }

  .embed-wrap {
    margin: 0 5% !important;
  }
}

@media only screen and (max-width: 750px) {
  .embed-wrap-mobile {
    display: none !important;
  }

  .embed-wrap {
    margin: 0 !important;
  }
}

@media only screen and (max-width:600px) {
  .embed-event-wrapper {

    .embed-header {
      width: 100%;
      min-height: 60px;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #ffffff;
      z-index: 1;
      align-items: center;

      .exit {
        p {
          display: none;
        }
      }
    }

    .embed-wrap {
      display: none !important;
    }

    .box-embed {
      position: relative;
    }

    .embed-wrap-mobile {
      display: block !important;
      background: #ffffff;

      .embed-content {
        padding: 24px 0;

        .header {
          font-weight: 500;
          font-family: "Roboto-Medium";
          font-size: 23px;
          line-height: 28px;
          color: #333333;
          text-align: left;
        }

        .sub-header {
          text-align: left;
          margin-top: 8px;
          padding-bottom: 24px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }

        .input-title {
          border: 1px dashed var(--primary-color, rgb(0, 105, 255));
        }

        .text-embed-code {
          padding: 12px;
          border: 1px dashed var(--primary-color, rgb(0, 105, 255));

          textarea {
            white-space: pre-line;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            font-family: 'Courier';
          }
        }

        .group-right-content {
          .right-content {
            font-weight: 500;
            font-family: "Roboto-Medium";
            font-size: 23px;
            line-height: 28px;
            color: #333333;
          }

          .describe {
            margin-top: 8px;
            padding-bottom: 24px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }

          .text-input {
            display: flex;

            .count-number {
              border-bottom: 1px solid #333333;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #bbbbbb;
            }

            .MuiOutlinedInput-inputMarginDense {
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #333333;
            }

            .MuiInputBase-inputMarginDense {
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #333333;
            }

            .MuiInput-underline::before {
              border-bottom: 1px solid #333333;
            }

            .MuiInput-underline::after {
              border-bottom: none;
            }
          }

          .group-choose-color {
            display: flex;
            margin-top: 25px;

            button {
              width: 100%;
              padding: 0;
              justify-content: space-between;
            }

            .show-color {
              display: inline-block;
              box-sizing: border-box;
              width: 24px;
              height: 24px;
              border: 1px solid rgba(0, 0, 0, 0.2);
              border-radius: 5px;
            }

            .text {
              text-transform: none;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #333333;
              text-align: left;
              width: 70%;
              font-family: 'Roboto-Regular';
            }
          }
        }
      }

      .container-title {
        .time-embed {
          font-weight: 500;
          font-family: "Roboto-Medium";
          font-size: 12px;
          line-height: 20px;
          color: #666666;
          margin-bottom: 8px;
        }

        .title-event {
          font-weight: 400;
          font-size: 23px;
          line-height: 28px;
          color: #333333;
        }
      }

      .btn-action {
        display: flex;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #ffffff;
        padding: 20px 5%;
        z-index: 1;

        .btn-cancel {
          text-transform: none;
          padding: 8px 12px;
          width: 50%;
          height: 36px;
          background: #ffffff;
          border: 1px solid var(--clr-primary);
          border-radius: 4px;
          box-shadow: none;

          font-weight: 500;
          font-family: "Roboto-Medium";
          font-size: 16px;
          line-height: 20px;
          color: var(--clr-primary);
        }

        .btn-copy {
          text-transform: none;
          background: var(--clr-primary);
          border-radius: 4px;
          padding: 8px 12px;
          width: 50%;
          height: 36px;
          box-shadow: none;

          font-weight: 500;
          font-family: "Roboto-Medium";
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
        }
      }

      .btn-style {
        text-transform: none;
      }
    }

    .background-white {
      background-color: #ffffff;
    }

    .box-embed {
      max-width: none;
      margin: 0 24px;
      padding-top: 60px;
      padding-bottom: 75px;

      .hidden {
        display: none !important;
      }

      .title {
        margin-top: 32px;
        margin-bottom: 16px;
        font-family: "Roboto-Regular";
        font-weight: 400;
        font-size: 23px;
        line-height: 28px;
      }

      .sub-title {
        align-items: center;
        margin-bottom: 16px !important;
        font-size: 16px !important;
      }

      .MuiContainer-root {
        padding: 0 !important;
      }

      .container-embed {
        display: none;
      }

      .container-embed-mobile {
        display: block !important;
        width: 100%;

        .embed-content {
          padding: left;
        }

        .btn-wrap {
          display: flex;
          background: #FFFFFF;
          border-radius: 5px;

          img {
            width: 123px;
            height: 134px;
            margin-left: 18px;
            margin-right: 14px;
          }

          .content {
            margin-top: 46px;

            .header-content {
              color: var(--clr-primary);
              font-weight: 500;
              font-family: "Roboto-Medium";
              font-size: 18px;
              line-height: 21px;
            }

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #666666;
            }
          }
        }
      }
    }
  }

}