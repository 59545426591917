@import "../../../stylesheets/mixins.scss";

.Wrapper {
  padding: 0 2em;
  // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
  margin-bottom: 3px;
  height: 60px;
  width: 100%;
  .Logo {
    width: 41px;
    height: 41px;
    margin-left: 1em;
    margin-right: 1em;
  }
  .Title {
    // color: var(--clr-primary);
    color: #333333;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    font-family: "Roboto-Bold";
    letter-spacing: -0.02em;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1;
  }

  .HeaderLeft{
    width: 25%;
    flex-basis: 30%;
  }
  .HeaderRight{
    width: 25%;
    flex-basis: 33.3333333%;
  }

  .HeaderContainer{
    justify-content: space-between;
  }

  .iconSearchMobile {
    display: none;
  }

  .searchHeader {
    background: #f6f7fa;
    border-radius: 16px;
    width: 400px;
    max-width: 400px;
    flex: 1;
    // padding: 4px 10px;
  }

  .MuiButton-containedPrimary{
    box-shadow: none;

    &:hover{
      box-shadow: none;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), var(--clr-primary);
    }
  }

  .IconNotification {
    // margin-left: 1em;
    // margin-right: 1em;
  }

  .searchMobile {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .HeaderIconAction{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 33px;

    img{
      width: 23px;
      height: 23px;
    }

    p{
      font-size: 13px;
    }
  }
   
}

.PopoverModule {
  //padding: 1em 2em;
  width: 186px;
  height: 124px;
  padding: 8px 0;
  .Link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    //padding: 0 18px;
    img {
      margin-right: 16px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
  }

  .Link:hover {
    background-color: #f6f7fa;
  }
}

.PopoverSetting {
  //padding: 1em 2em;
  padding: 8px 0;
  width: 186px;
  .Link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    img {
      margin-right: 16px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      font-family: "Roboto-Regular";
    }
  }
  .Link:hover {
    background-color: #f6f7fa;
  }
}


.PopoverNotification {
  height: 446px;
  width: 417px;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  .TitleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);;
    background-color: #ffffff;
    height: 60px;
  }
  .ContentWrapper {
    overflow-x: hidden;
    overflow: overlay;
    height: calc(100% - 60px);
    border-bottom: 4px solid var(--clr-primary);
    &::-webkit-scrollbar {
      width: 0.2rem;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(169, 169, 169);
      outline: 1px solid slategrey;
    }
  }
}

@media only screen and (max-width: 790px) {
  .Wrapper {
    .iconBtnHeader {
      padding: 5px !important;
    }
    .Logo {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
    .IconNotification {
      // margin-left: 1%;
      // margin-right: 5%;
    }
    .HeaderContainer{
      justify-content: space-between;
    }
    
    .HeaderIconAction{
      margin-right: 4px !important;

      .HeaderIconActionLabel{
        display: none;
      }
    }
    
    // .HeaderRight{
    //   justify-content: space-between;
    // }
  }
}

@media only screen and (max-width: 600px) {

  .LogoCompanyHead{
    display: flex;
    align-items: center;
    padding: 12px 25px 12px 12px;
    justify-content: space-between;
  }

  .LogoCompanyName{
    display: flex;
    align-items: center;
    .Logo{
      width: 35px;
      height: 35px;
    }

    .Title{
      font-family: 'Roboto-Bold';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;

      letter-spacing: -0.02em;
      margin-left: 12px;
      color: var(--clr-primary);
    }
  }
  .Wrapper {
    padding: 0 6.4vw;
    // box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    height: 60px;
    width: 100%;
    .searchHeader {
      display: none;
    }
    .iconSearchMobile {
      display: block;
    }
    .iconBtnHeader {
      padding: 6px !important;
      margin: 0 2px;
    }
    .IconNotification {
      // margin-left: 2px;
      // margin-right: 5.5%;
    }
    .HeaderLeft {
      display: flex;
      width: auto;
      flex-basis: auto;
      .Logo {
        width: 41px;
        height: 41px;
        margin-right: 8px;
        margin-left: 0;
      }
      .Title {
        // font-size: 16px;
        // line-height: 24px;

        display: none;
      }
    }
    .HeaderRight{
      width: auto;
      flex-basis: auto;
    }
  }
  
  .PopoverModule {
    width: 100%;
    height: 100%;
    padding: 8px 0;
  }

  .PopoverNotification {
    height: 550px;
    width: 100%;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    .TitleWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em;
      border-bottom: 1px solid #c7c7c7;
      background-color: #ececec;
      height: 80px;
    }
    .ContentWrapper {
      overflow: overlay;
      height: calc(100% - 80px);
      &::-webkit-scrollbar {
        width: 0.2rem;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(169, 169, 169);
        outline: 1px solid slategrey;
      }
    }
  }
}